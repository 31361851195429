import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Ausfueller} from "@form-viewer/model/Ausfueller";
import {LogEintrag} from "@shared/model/LogEintrag";
import {AktuelleBerechtigungService} from "./AktuelleBerechtigungService";

@Injectable({ providedIn: "root" })
export class LoggingService {
	constructor(
		private http: HttpClient,
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private router: Router
	) {}

	async logError(error: HttpErrorResponse) {
		try {
			let logEintrag = new LogEintrag();
			logEintrag.user = this.findAusfueller()?.id;
			logEintrag.institution = this.aktuelleBerechtigungService.getInstitutionsId();
			logEintrag.recherchekomplex = this.aktuelleBerechtigungService.getRecherchekomplexId();
			logEintrag.currentUrl = this.router.url;
			logEintrag.errorUrl = error.url;
			logEintrag.nachricht = error.message;
			logEintrag.status = error.status;

			this.http.post('/api/logging', logEintrag);
		} catch (ignore) {}
	}

	findAusfueller(): Ausfueller {
		try {
			let ausfuellerItem = localStorage.getItem('ausfueller');
			if (ausfuellerItem && ausfuellerItem.trim()) {
				return new Ausfueller(JSON.parse(this.decodeB64(ausfuellerItem)));
			} else {
				return null;
			}
		} catch (ignore) {}
		return null;
	}

	decodeB64(str): string {
		return decodeURIComponent(escape(atob(str)));
	}
}
