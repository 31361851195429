<app-ausfueller-verwaltung-dialog #dialog></app-ausfueller-verwaltung-dialog>

<nav class="navbar navbar-abschnitt">
	<div class="container">
		<ul class="nav navbar-nav">
			<li class="navbtn back" title="Zum Portal">
				<a (click)="onZurueckZurUebersichtClicked()"
				   *ngIf="zeigeZurueckButton()"
				   routerLink="/portal"
				   title="Zum Portal"
				   data-bs-toggle="tooltip" data-bs-placement="bottom">
					<i class="icon icon-chevron-small-left"></i>
				</a>
			</li>
			<li class="switcher">
				<div class="headline">
					{{ fragebogen.name }}
				</div>
				<div class="description">
					Der Fragebogen enthält {{ anzahlAbschnitte | plural:'einen Abschnitt':'Abschnitte' }}.
				</div>
			</li>
			<li class="navbtn ausfueller" *ngIf="istFragebogenausfuellerVerwaltenAktiv()">
				<a [routerLink]="['/benutzerverwaltung']">
					<i class="glyphicon glyphicon-user"></i> +
				</a>
			</li>
		</ul>
	</div>
</nav>
