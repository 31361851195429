import { HttpErrorResponse } from '@angular/common/http';
import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { Alert } from '@shared/alert/service/Alert';
import { HttpErrorUtil } from '@shared/alert/service/HttpErrorUtil';
import { LoggingService } from "@shared/service/LoggingService";
import { NavigationService } from "@shared/service/NavigationService";
import { ArrayUtil } from '@shared/util/ArrayUtil';
import { HttpUtil } from "@shared/util/HttpUtil";
import { StringUtil } from "@shared/util/StringUtil";
import { removeElement } from "@util/removeElement";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { MessageService } from "primeng/api";

const debug = require('debug')('AlertService');

@Directive()
@Injectable({providedIn: 'root'})
export class AlertService {
	private static TIMEOUT_MS = {
		'danger': 10000,
		'warning': 8000,
		'info': 5000,
		'success': 5000,
	};

	@Output()
	newAlert = new EventEmitter();

	@Output()
	removeAlert = new EventEmitter();
	public handleHttpError: any;
	@BlockUI() blockUI: NgBlockUI;
	private alerts: Alert[] = [];

	constructor(private navigationService: NavigationService, private loggingService: LoggingService, private messageService: MessageService
	) {
		this.handleHttpError = this.handleHttpErrorMethod.bind(this);
	}

	private static getTimeoutMs(type: string) {
		return AlertService.TIMEOUT_MS[type];
	}

	showDanger(msg: string) {
		this.messageService.add({
			severity: 'error',
			summary: msg,
			sticky: true
		});
		this.blockUI.stop()
	}

	showWarning(msg: string) {
		this.messageService.add({
			severity: 'warn',
			summary: msg,
			life: 5000
		});
		this.blockUI.stop()
	}

	showInfo(msg: string) {
		this.messageService.add({
			severity: 'info',
			summary: msg,
			life: 4000
		});
	}

	showSuccess(msg: string) {
		this.messageService.add({
			severity: 'success',
			summary: msg,
			life: 4000
		});
	}

	getAlerts(): Alert[] {
		return this.alerts;
	}

	dismissAlert(alert: Alert): void {
		this.dismiss(alert);
	}

	clearAlerts(): void {
		this.messageService.clear();
	}

	private show(type: string, msg: string) {
		const alert = new Alert(type, msg);
		this.alerts.push(alert);

		if (this.hasTimeoutConfigured(type)) {
			this.scheduleDismissal(alert, AlertService.getTimeoutMs(type));
		}

		this.newAlert.emit(alert);
		return alert;
	}

	private hasTimeoutConfigured(type: string) {
		return AlertService.getTimeoutMs(type) > 0;
	}

	private scheduleDismissal(alert: Alert, timeout_ms: number) {
		const alertService = this;
		setTimeout(() => {
			alertService.dismiss(alert);
		}, timeout_ms);
	}

	private dismiss(alert: Alert) {
		removeElement(this.alerts, alert);
		this.removeAlert.emit(alert);
	}

	private handleHttpErrorMethod(error: HttpErrorResponse) {
		debug('HTTP-Error', error);

		if ([HttpUtil.STATUS_UNAUTHORIZED, HttpUtil.STATUS_FORBIDDEN].includes(error.status)) {
			debug('Fehlende Berechtigung oder der User wurde zwischenzeitlich ausgeloggt -> Redirect zu Fehlerseite');
			this.navigationService.goToLoggedOutPage();
		} else if ([HttpUtil.STATUS_CONFLICT, HttpUtil.STATUS_PRECONDITION_FAILED].includes(error.status)) {
			this.showConflictWarning(error.error.message);
			return Promise.reject(error.error.message || error);
		} else if (error.error) {
			// error.error ist der eigentlich zurueckgelieferte Fehler
			const errorBody = error.error;

			if (errorBody.validationResults) {
				// Fehler aus der "manuellen" Antwortvalidierung
				this.showViolationErrors(errorBody);
			} else if (ArrayUtil.isNonEmptyArray(errorBody.errors)) {
				// Fehler aus der @Valid Validierung
				this.showAnnotationViolationErrors(errorBody);
			} else if (StringUtil.isNonBlank(errorBody.message)) {
				this.showServerErrorsWithDescription(errorBody.message);
			} else {
				this.showServerErrors(errorBody);
			}

			return Promise.reject(errorBody.message || error);
		} else {
			// Von angular gesetzte Meldung
			this.showServerErrorsWithDescription(error.message);
			this.loggingService.logError(error);
			return Promise.reject(error.message || error);
		}

		return null;
	}

	private showViolationErrors(error) {
		this.buildViolationErrorMessage(error, HttpErrorUtil.formatValidationResults(error.validationResults));
	}

	private showAnnotationViolationErrors(error: any) {
		this.buildViolationErrorMessage(error, HttpErrorUtil.formatAnnotationValidationResults(error));
	}

	private buildViolationErrorMessage(error: any, formattedValidationErrors: string) {
		this.showWarning([
			'<span class="title">Validierungsfehler</span><br>',
			'Einige der übertragenen Daten entsprachen nicht den Erwartungen.<br>',
			formattedValidationErrors/*, JUREC-102: technische Fehler sollen nicht mehr angezeigt werden
			HttpErrorUtil.formatTechnicalDescription(error)*/
		].join(''));

		this.blockUI.resetGlobal()

	}

	private showServerErrors(error) {
		this.showServerErrorsWithDescription(''
			//HttpErrorUtil.formatTechnicalDescription(error) JUREC-102: technische Fehler sollen nicht mehr angezeigt werden
		);
	}


	private showServerErrorsWithDescription(description: string) {

		if (description === "Abschnitt ist nicht mehr durch Sie gesperrt.") {

			this.showConflictWarning(description);

		} else if (description.includes('401')) {
			this.showConflictWarning('Ihnen fehlen die nötigen Berechtigungen, um sich bei JUVE Recherche anzumelden.')
		} else {
			this.showDanger([
				// '<span class="title">Fehler</span>',
				"Fehler",
				'',
				HttpErrorUtil.formatDatePrefix() + 'Bei der Kommunikation mit dem Server ist ein Fehler aufgetreten.',
				'',
				description
			].join('\n'));
		}

		this.blockUI.resetGlobal()
	}

	private showConflictWarning(description) {
		this.showWarning([
							 "Warnung",
							 '',
							 description,
							 ''
						 ].join('\n'));
	}
}
