<br>
<p-table #dt
		 [(selection)]="selectedValue"
		 [globalFilterFields]="['name', 'eingeladeneAusfueller', 'anzahlFrageboegen', 'status']"
		 [paginator]="true"
		 [rows]="rows"
		 [showCurrentPageReport]="true"
		 [value]="recherchekomplexListe"
		 sortField="name"
		 [sortOrder]="1"
		 dataKey="name"
		 selectionMode="single"
		 styleClass="p-datatable-striped">

	<ng-template pTemplate="caption">
		<div class="flex flex-row justify-content-between">
			<div>{{recherchekomplexListe?.length}} {{recherchekomplexListe?.length === 1 ? 'Recherchekomplex gefunden (Bei dem Sie administrative Rechte haben)' : 'Recherchekomplexe gefunden (Bei denen Sie administrative Rechte haben)'}}</div>
			<div class="p-input-icon-left">
				<i class="pi pi-filter"></i>
				<input (input)="dt.filterGlobal($any($event.target).value, 'contains')"
					   pInputText
					   class="searchField"
					   placeholder="Filter über alle Spalten"
					   style="margin-right: 5rem"
					   type="text">
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th *ngFor="let col of cols"
				pSortableColumn={{col.field}}>
				<div [title]="col.tooltip"> {{col.header}}
				<p-sortIcon field={{col.field}}></p-sortIcon></div>
			</th>
			<th style="width: 5rem"></th>
		</tr>
		<tr>
			<th *ngFor="let col of cols">
				<span class="p-input-icon-left p-column-filter-row">
				<i class="pi pi-filter"></i>
				<input (input)="dt.filter($any($event.target).value, col.field, col.filterMatchMode='contains')"
					   pInputText
					   class="p-column-filter-row"
					   type="text">
				</span>
			</th>
			<th></th>
		</tr>
	</ng-template>

	<ng-template let-recherchekomplex pTemplate="body">
		<tr (dblclick)="goToRecherchekomplex(recherchekomplex)"
			class="selectableRow"
			[pSelectableRow]="selectedValue">
			<td *ngFor="let col of cols">{{recherchekomplex[col.field]}}</td>
			<td style="text-align: center">
				<a [queryParams]="{recherchekomplexName: recherchekomplex.name}"
				   id="routeTo_{{recherchekomplex.id}}"
				   routerLink="recherchekomplexe/{{recherchekomplex.id}}">
					<button class="table-button wideIconButton table-text-button p-button-raised"
							label="Bearbeiten"
							pButton
							data-bs-toggle="tooltip" data-bs-placement="top"
							type="button"
							style="margin-left: 5px;"
							title="Zum Recherchekomplex eingeladene Benutzer ansehen und bearbeiten">
						<fa-icon [icon]="faPen"></fa-icon>
					</button>
				</a>
			</td>
		</tr>
	</ng-template>

</p-table>
