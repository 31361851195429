import {Component} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {RouterLink} from '@angular/router';
import {UserService} from "../../form-viewer/service/UserService";
import {MessageComponent} from './../../shared/components/message/MessageComponent';

@Component({
	templateUrl: "./ForbiddenComponent.html",
	standalone: true,
	imports: [MessageComponent, RouterLink],
})
export class ForbiddenComponent {
	constructor(private userService: UserService, private title: Title) {
		title.setTitle("Zugang verweigert - Juve Recherche");
	}

	getStartseite() {
		if (
			this.userService.getAusfueller().istAusfueller()
		) {
			return "/";
		}
		if (
			this.userService.getAusfueller().istAutor()
		) {
			return "/editor";
		}
		//TODO: weitere Rollen abdecken
		return "/";
	}
}
