/* eslint-disable max-len */
import {Location, LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {ApplicationRef, LOCALE_ID, NgModule, Type} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {InstitutionsAuswahlComponent} from '@form-editor/antworten/institutions-auswahl/InstitutionsAuswahlComponent';
import {FormViewerComponent} from '@form-viewer/FormViewerComponent';
import {ViewerPortalRecherchekomplexComponent} from '@form-viewer/viewer-portal-recherchekomplex/ViewerPortalRecherchekomplexComponent';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {PasswordZuruecksetzenFormularComponent} from '@passwort-eingabe/PasswordZuruecksetzenFormularComponent';

import {BlockUIModule} from 'ng-block-ui';
import {AlertComponent, AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {CookieService} from "ngx-cookie-service";
import {MessageService} from "primeng/api";
import {AuthModule} from "../auth/auth.module";
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CustomHttpInterceptor} from "./errors/http.custom.interceptor";
import {FormEditorComponent} from './form-editor/FormEditorComponent';
import {EditorFragebogenUebersichtComponent} from './form-editor/fragebogen-uebersicht/EditorFragebogenUebersichtComponent';
import {FragebogenDruckenComponent} from './form-viewer/fragebogen-drucken/FragebogenDruckenComponent';
import {metaReducers, reducers} from './reducers';
import {AuthGuardEditor} from './security/AuthGuardEditor';
import {AuthGuardViewer} from './security/AuthGuardViewer';
import {AuthGuardViewerRK} from './security/AuthGuardViewerRK';
import {PasswortZuruecksetzenComponent} from "./security/passwort-zuruecksetzen/PasswortZuruecksetzenComponent";
import {XhrInterceptor} from './security/xhr.interceptor';
import {institutionFeatureKey, institutionReducer} from './store/reducer/institution.reducer';
import {recherchekomplexFeatureKey, recherchekomplexReducer} from './store/reducer/recherchekomplex.reducer';

export function jurecEasing(t: number, b: number, c: number, d: number): number {
	if ((t /= d / 2) < 1) {
		return c / 2 * t * t + b;
	}
	return -c / 2 * ((--t) * (t - 2) - 1) + b;
}


@NgModule({
	imports: [
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		AlertModule.forRoot(),
		ModalModule.forRoot(),
		PopoverModule.forRoot(),
		TooltipModule.forRoot(),
		BsDropdownModule.forRoot(),
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreModule.forFeature(
			recherchekomplexFeatureKey,
			recherchekomplexReducer
		),
		StoreModule.forFeature(institutionFeatureKey, institutionReducer),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		BlockUIModule.forRoot({
			delayStart: 0,
			delayStop: 1000,
		}),
		AuthModule,
		StoreModule.forRoot({}, {}),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([]),
	],
	providers: [
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomHttpInterceptor,
			multi: true,
		},
		CookieService,
		MessageService,
		AuthGuardEditor,
		AuthGuardViewer,
		AuthGuardViewerRK,
		{ provide: LOCALE_ID, useValue: "de" },
		{ provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true },
	],
	// entryComponents: [AppComponent, PasswortZuruecksetzenComponent],
})

/* eslint-disable */
export class AppModule {
	/**
	 * Check if an Element matching a Tag-Name is present in the page
	 *
	 * @param elementName Tag-Name od the Element
	 * @returns {boolean} is it present?
	 */
	static isElementPresent(elementName: string): boolean {
		return document.getElementsByTagName(elementName).length > 0;
	}

	/**
	 * Bootstrap an optional Component if an Element is present in the page
	 *
	 * @param appRef
	 * @param elementName Tag-Name of the Element that needs to be present
	 * @param component Component to bootstrap
	 */
	static bootstrapComponentIfPresent<T>(
		appRef: ApplicationRef,
		elementName: string,
		component: Type<T>
	): void {
		if (AppModule.isElementPresent(elementName)) {
			appRef.bootstrap(component);
		}
	}

	/**
	 * Dynamic bootstrap of optional Components
	 *
	 * @param appRef
	 */
	async ngDoBootstrap(appRef: ApplicationRef) {
		AppModule.bootstrapComponentIfPresent(appRef, "app-root", AppComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-form-editor", FormEditorComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-editor-fragebogen-uebersicht", EditorFragebogenUebersichtComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-form-viewer", FormViewerComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-fragebogen-drucken", FragebogenDruckenComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-institutions-auswahl", InstitutionsAuswahlComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-viewer-portal-recherchekomplex", ViewerPortalRecherchekomplexComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-alert", AlertComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-passwort-zuruecksetzen", PasswortZuruecksetzenComponent);
		AppModule.bootstrapComponentIfPresent(appRef, "app-passwort-zuruecksetzen-formular", PasswordZuruecksetzenFormularComponent);
	}
}

registerLocaleData(localeDe, 'de');
