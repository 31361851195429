import {AusfuellerType} from '@form-viewer/model/AusfuellerType';
import {Berechtigung} from '@form-viewer/model/Berechtigung';
import {Institution} from "@shared/model/Institution";
import {Recherchekomplex} from "@shared/model/Recherchekomplex";
import {AnredeUtil} from '@util/AnredeUtil';
import {UUID} from '@util/export-types';

export class Ausfueller {

	id: UUID;
	anrede: string;
	anzeigename: string;
	email: string;

	// @deprecated
	type: AusfuellerType;

	berechtigungen: Berechtigung[] = [];
	authorityStrings: string[]
	enabled: boolean
	archiviert: boolean
	username: string

	constructor(ausfueller?: Ausfueller) {
		if (ausfueller) {
			this.id = ausfueller.id;
			this.anrede = ausfueller.anrede;
			this.anzeigename = ausfueller.anzeigename;
			this.email = ausfueller.email;
			this.authorityStrings = ausfueller.authorityStrings;
			this.enabled = ausfueller.enabled
			this.archiviert = ausfueller.archiviert
			this.email = ausfueller.username
			this.username = ausfueller.username

			if (Array.isArray(ausfueller.berechtigungen)) {
				this.berechtigungen = ausfueller.berechtigungen.map(b => new Berechtigung(b));
			}
		}
	}

	static build(ausfueller: any): Ausfueller {
		return new Ausfueller(ausfueller as Ausfueller);
	}

	static buildList(list: any[]): Ausfueller[] {
		return list.map(ausfueller => Ausfueller.build(ausfueller));
	}

	getNameMitDativAnrede() {
		return AnredeUtil.dekliniere(this.anrede) + ' ' + this.anzeigename;
	}

	istRecherchekomplexAdmin() {
		return this.berechtigungen.filter(b => b.recherchekomplexAdmin).length > 0
	}

	istRecherchekomplexAdminForKomplexId(komplexId: string) {
		return this.berechtigungen.filter(b => b.recherchekomplex.id == komplexId).filter(b => b.recherchekomplexAdmin).length > 0
	}

	istRecherchekomplexCoAdminForKomplexId(komplexId: string) {
		return this.berechtigungen.filter(b => b.recherchekomplex.id == komplexId).filter(b => b.recherchekomplexAdmin).length > 0
	}

	getRecherchekomplexWithAdminRights(aktuelleInstitution: Institution) {
		let adminKomplexSet = new Set<Recherchekomplex>()

		for (const berechtigung of this.berechtigungen) {
			if (berechtigung.recherchekomplexAdmin) {
				if (berechtigung.institution.id === aktuelleInstitution.id) {
					adminKomplexSet.add(berechtigung.recherchekomplex)
				}else {
					// console.log("KEINE RECHTE")
					// console.log(berechtigung)
				}
			}
		}

		return adminKomplexSet
	}

	istAusfueller() {
		return this.authorityStrings.includes('ROLE_AUSFUELLER');
	}

	istAutor() {
		return this.authorityStrings.includes('ROLE_FRAGEBOGENAUTOR');
	}
}
