/* eslint-disable-next-line max-len */
import {NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AbschnittEditorService} from '@form-editor/service/AbschnittEditorService';

/* eslint-disable-next-line max-len */
import {AusfuellerVerwaltungDialogComponent} from '@form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
import {
	AbschnittsausfuellerVerwaltungFormConfiguration
} from '@form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AusfuellerBerechtigungsService} from '@form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from '@form-viewer/service/PreviewService';
import {Abschnitt} from '@shared/model/Abschnitt';
import {Fragebogen} from '@shared/model/Fragebogen';
import {PreventDefaultDirective} from "@util/PreventDefaultDirective";
import {SimplePluralPipe} from "@util/SimplePluralPipe";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AbschnittSaveEventServiceService} from "../service/AbschnittSaveEventServiceService";

@Component({
	selector: "app-viewer-abschnitt-navbar",
	templateUrl: "./ViewerAbschnittNavbarComponent.html",
	styleUrls: ["./ViewerAbschnittNavbarComponent.less"],
	providers: [AbschnittEditorService],
	standalone: true,
			   imports: [SimplePluralPipe, NgIf, AusfuellerVerwaltungDialogComponent, PreventDefaultDirective, RouterLink],
})
export class ViewerAbschnittNavbarComponent {
	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	abschnitt: Abschnitt;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abgeschlossen: boolean;

	@Output()
	back = new EventEmitter();

	@ViewChild("dialog", { static: true })
	dialog: AusfuellerVerwaltungDialogComponent;

	private saving: boolean;

	constructor(
		private previewService: PreviewService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private abschnSaveSvc: AbschnittSaveEventServiceService
	) {}

	ngOnInit() {
		this.abschnSaveSvc.savingEventListner().subscribe((info) => {
			// console.log(info); // here you get the message from Child component
			this.saving = JSON.parse(String(info));
		});
	}

	onZurueckZurUebersichtClicked() {
		try {
			this.blockUI.start("Daten werden verarbeitet...");
			this.back.emit();
		} finally {
			setTimeout(() => {
				this.blockUI.stop();
			}, 500);
		}
	}

	istAbschnittsausfuellerVerwaltenAktiv() {
		return (
			this.previewService.isNotInPreview() &&
			!this.abgeschlossen &&
			this.ausfuellerBerechtigungsService.darfAbschnittsausfuellerFuerFragebogenVerwalten(
				this.fragebogen
			)
		);
	}

	abschnittsausfuellerVerwaltenDialogOeffnen() {
		this.dialog.show(//TODO: JUREC-472/481: Dialog ganz ausbauen oder drin lassen für ausführlichere Variante
			new AbschnittsausfuellerVerwaltungFormConfiguration(this.abschnitt, this.fragebogen)
		);
	}

	isSaving() {
		return this.saving;
	}
}
