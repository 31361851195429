import {Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faPencilAlt, faTrashCan, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from 'primeng/paginator';
import {Table, TableModule} from "primeng/table";
import {AusfuellerVerwaltungFormConfiguration} from "../../../form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration";
import {UserService} from "../../../form-viewer/service/UserService";
import {AlertService} from "../../../shared/alert/service/AlertService";
import {VerwaltbarerAusfuellerDto} from "../../model/VerwaltbarerAusfuellerDto";
import {VerwaltbarerAusfuellerInstitutionDto} from "../../model/VerwaltbarerAusfuellerInstitutionDto";
import {VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto} from "../../model/VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {VerwaltbarerAusfuellerRecherchekomplexDto} from "../../model/VerwaltbarerAusfuellerRecherchekomplexDto";
import {AusfuellerService} from "../../services/AusfuellerService";
import {EventService} from "../../services/EventService";
import {
	BenutzerverwaltungDialogLoescheAusfuellerComponent
} from "../alt-benutzerverwaltung-dialog-loesche-ausfueller/BenutzerverwaltungDialogLoescheAusfuellerComponent";
import {
	BenutzerverwaltungDialogLoescheAusfuellerConfiguration
} from "../alt-benutzerverwaltung-dialog-loesche-ausfueller/BenutzerverwaltungDialogLoescheAusfuellerConfiguration";
import {BenutzerBearbeitenComponent} from "./benutzer-bearbeiten/benutzer-bearbeiten.component";

@Component({
	selector: "app-registrierte-benutzer",
	templateUrl: "./registrierte-benutzer.component.html",
	styleUrls: ["./registrierte-benutzer.component.less"],
	providers: [DialogService, ConfirmationService],
	standalone: true,
	imports: [
		TableModule,
		PaginatorModule,
		ConfirmDialogModule,
		FontAwesomeModule,
		InputTextModule,
	],
})
export class RegistrierteBenutzerComponent implements OnInit, OnDestroy {
	@ViewChild("dialog", { static: true })
	dialog: BenutzerverwaltungDialogLoescheAusfuellerComponent;
	configuration: AusfuellerVerwaltungFormConfiguration;

	public ausfuellerListe: VerwaltbarerAusfuellerDto[];
	public verwaltbarerAusfueller: VerwaltbarerAusfuellerDto;
	public verwaltbarerRecherchekomplex: VerwaltbarerAusfuellerRecherchekomplexDto;
	public verwaltbareAusfuellerMitInstitutionUndRecherchekomplexList: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto[];
	public selectedValue: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public anreden: any[];
	@BlockUI() blockUI: NgBlockUI;

	ref: DynamicDialogRef;
	public rows: number;
	faPen = faPencilAlt;
	faUserPlus = faUserPlus;
	faTrashCan = faTrashCan;
	private screenWidth: number;
	private screenHeight: number;
	private institutionId: string;
	private institutionName: string;

	constructor(
		public ausfuellerService: AusfuellerService,
		public dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		private alertService: AlertService,
		private ngZone: NgZone,
		public userService: UserService,
		private eventService: EventService
	) {}

	rufeNeueAusfuellerlisteAb() {
		// console.log("rufeNeueAusfuellerlisteAb")

		this.ausfuellerService
			.holeAusfuellerForCurrentInst(true)
			.then((ausfuellerDtoListe) => {
				this.ausfuellerListe = ausfuellerDtoListe.filter(
					(ausfueller) => ausfueller.enabled && !ausfueller.archiviert
				);

				this.verwaltbareAusfuellerMitInstitutionUndRecherchekomplexList =
					[];
				if (this.ausfuellerListe) {
					this.ausfuellerListe.forEach((ausfueller) => {
						let wrapper =
							new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto(
								ausfueller
							);
						if (wrapper) {
							this.verwaltbareAusfuellerMitInstitutionUndRecherchekomplexList.push(
								wrapper
							);
						}
					});
				}
			});
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.calcTabelRows(event.target.innerWidth, event.target.innerHeight);
	}

	ngOnInit() {
		this.calcTabelRows(window.innerWidth, window.innerHeight);
		// this.rufeNeueAusfuellerlisteAb();
		this.anreden = [
			{ label: "Herr", value: "herr" },
			{ label: "Frau", value: "frau" },
		];

		this.configuration =
			new BenutzerverwaltungDialogLoescheAusfuellerConfiguration();
		this.eventService
			.userActiveToggledOnBenutzerListener()
			.subscribe((info) => {
				// console.log("refreshing ausfueller liste on active toggle" + " registriert")
				this.rufeNeueAusfuellerlisteAb();
			});

		// this.isAlive()
	}

	bearbeiteAusfueller(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.isAlive();
		this.messageService.clear();

		//TODO: Umbau auf REST
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary:
					"Es konnte kein Benutzer zur Bearbeitung geladen werden",
			});
		}

		if (this.verwaltbarerAusfueller != null) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: "Benutzer bearbeiten",
				width: "75%",
				height: "100%",
				styleClass: "FullScreenDialog",
				modal: true,
				closeOnEscape: true,
				closable: true,
				contentStyle: {
					height: "100%",
					width: "100%",
					"max-width": "100%",
					"max-height": "100%",
					overflow: "auto",
				},
				baseZIndex: 10000,
				style: { "max-width": "100%", "max-height": "100%" },
				data: {
					verwaltbarerAusfueller: this.verwaltbarerAusfueller,
					einladung: false,
				},
			});
		}
	}

	entferneAusfuellerAusUnternehmen(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		if (
			this.getAusfuellerObj(
				verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
			) == null
		) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.getCurrentInstitute();

		this.ausfuellerService
			.isAnyAdminForInstitute(
				this.verwaltbarerAusfueller.id,
				this.userService.getAktuelleInstitution().id
			)
			.then((returnVal) =>
				this.confirmDelete(
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto,
					returnVal
				)
			);
	}

	isAlive() {
		this.userService.isAlive();
	}

	isSelfEditing() {
		if (
			this.userService.getAusfueller().id ==
			this.verwaltbarerAusfueller.id
		) {
			return true;
		}
		return false;
	}

	erstellePwdsNeu() {
		this.confirmationService.confirm({
			message:
				"\nWollen Sie für alle, nicht archivierten, Benutzer einen neuen Registrierungscode per EMail schicken und ggf fehlenden Benutzer im Keycloak anlegen?\n",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			header: "Bitte bestätigen Sie die Generierung der neuen Registrierungscodes",
			closeOnEscape: false,
			icon: "pi pi-info-circle",
			accept: () => {
				this.blockUI.start("Generiere Passwörter");
				this.userService
					.generatePwdForAll()
					.then(() => {
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary:
								"Die Generierung der Passwörter wurde abgeschlossen",
						});
					})
					.catch(this.alertService.handleHttpError)
					.finally(this.blockUI.stop);
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Die Generierung wird abgebrochen.",
						});
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Die Generierung wird abgebrochen.",
						});
						break;
				}
			},
		});
	}

	erstelleAusfueller() {
		console.log("erstelleAusfueller");
		this.verwaltbarerAusfueller = new VerwaltbarerAusfuellerDto();

		// console.log(JSON.parse(localStorage.getItem("institution")))
		//
		// let inst = JSON.parse(localStorage.getItem("institution"))
		let newInst = new VerwaltbarerAusfuellerInstitutionDto();

		// let currentUser = this.userService.getAusfueller()
		// for (const ausfueller of this.verwaltbareAusfuellerMitInstitutionUndRecherchekomplexList) {
		// 	if (currentUser.id == ausfueller.ausfuellerId) {
		newInst.id = this.userService.getAktuelleInstitution().id;
		newInst.anzeigename =
			this.userService.getAktuelleInstitution().anzeigename;
		this.verwaltbarerAusfueller.institutionen.push(newInst);
		this.verwaltbarerAusfueller.institionListAsString =
			this.userService.getAktuelleInstitution().anzeigename;
		// break
		// }
		// }

		// console.log(this.verwaltbarerAusfueller)

		if (this.verwaltbarerAusfueller != null) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: "Benutzer erstellen",
				width: "75%",
				height: "100%",
				styleClass: "FullScreenDialog",
				closeOnEscape: true,
				closable: true,
				modal: true,
				contentStyle: {
					height: "100%",
					width: "100%",
					"max-width": "100%",
					"max-height": "100%",
					overflow: "auto",
				},
				baseZIndex: 10000,
				style: { "max-width": "100%", "max-height": "100%" },
				data: {
					verwaltbarerAusfueller: this.verwaltbarerAusfueller,
				},
			});
		}
	}

	loeschenVorbereiten(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.dialog.show();
	}

	getAnzahlRegistrierteNutzer() {
		return (this.ausfuellerListe || []).filter((o) => o.enabled).length;
	}

	clear(table: Table) {
		table.clear();
	}

	confirmDeleteAdminUser(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.confirmationService.confirm({
			message:
				'\nWollen Sie den Benutzer "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" wirklich löschen?\n',
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			header: "Bitte Löschen bestätigen",
			icon: "pi pi-info-circle",
			accept: () => {
				this.messageService.clear();
				this.messageService.add({
					severity: "info",
					summary: "Benutzer wird gelöscht...",
				});
				this.alleRechteEinesAusfuellersLoeschen(
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
				);
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
				}
			},
		});
	}

	alleRechteEinesAusfuellersLoeschen(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	) {
		this.getAusfuellerObj(
			verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
		);
		if (this.verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({
				severity: "warn",
				life: 5000,
				summary: "Es konnte kein Benutzer zum löschen geladen werden",
			});
		}

		this.ausfuellerService
			.alleRechteEinesAusfuellersLoeschen(this.verwaltbarerAusfueller)
			.then(() => {
				this.rufeNeueAusfuellerlisteAb();
				this.messageService.add({
					severity: "success",
					summary:
						"Der Benutzer wurde gelöscht und alle Rechte wurden entfernt.",
				});
				this.eventService.emitRecherchekomplexEingeladeneUserEvent(
					"benutzer-bearbeiten"
				);
				this.eventService.emitUserOffeneEinladungenEvent(
					"offene-einladung-bearbeiten"
				);
				this.eventService.emitUserActiveToggledEventInactive(
					"benutzer-bearbeiten"
				);
			});
	}

	baumveraenderungBehandeln() {
		this.rufeNeueAusfuellerlisteAb();
	}

	trackeVeraenderungenAmBaum(index, verwaltbarerAusfueller) {
		return verwaltbarerAusfueller.id;
	}

	onPageChange(pageIndex) {
		// alert(pageIndex);
	}

	ngOnDestroy() {
		if (this.ref) {
			this.ref.close();
		}
	}

	private confirmDelete(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto,
		anyAdminForInstitute: boolean
	) {
		if (anyAdminForInstitute) {
			this.messageService.clear();
			this.messageService.add({
				severity: "error",
				life: 8000,
				summary:
					"Dieser Benutzer hat JUVE Administrator Rechte in ihrem Unternehmen. Diese Rechte können nur durch JUVE administriert werden!",
			});
			return;
		}
		if (
			this.userService.getAusfueller().id ==
			this.verwaltbarerAusfueller.id
		) {
			this.messageService.clear();
			this.messageService.add({
				severity: "error",
				life: 8000,
				summary: "Sie können sich nicht selbst löschen!",
			});
			return;
		}

		this.messageService.clear();

		this.confirmationService.confirm({
			message:
				'\nWollen Sie den Benutzer "' +
				this.verwaltbarerAusfueller.anzeigename +
				'" wirklich löschen?\n',
			acceptLabel: "Löschen",
			rejectLabel: "Abbrechen",
			header: "Bitte Löschen bestätigen",
			icon: "pi pi-info-circle",
			accept: () => {
				this.messageService.clear();
				this.messageService.add({
					severity: "info",
					summary: "Benutzer wird gelöscht...",
				});
				this.alleRechteEinesAusfuellersLoeschen(
					verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
				);
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: "info",
							summary: "Löschen wurde abgebrochen",
						});
						this.verwaltbarerAusfueller = null;
						break;
				}
			},
		});
	}

	private getCurrentInstitute() {
		let currentInst = this.userService.getAktuelleInstitution();

		this.institutionId = currentInst.id;
		this.institutionName = currentInst.anzeigename;

		return this.institutionId;
	}

	private calcTabelRows(width, height) {
		this.screenWidth = width;
		this.screenHeight = height;
		this.rows = Math.floor(
			(this.screenHeight - 65 - 40 - 40 - 210) / 44 - 1
		);
		if (this.rows < 4) {
			this.rows = 4;
		}
	}

	private getAusfuellerObj(
		verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto
	): VerwaltbarerAusfuellerDto {
		for (let ausfueller of this.ausfuellerListe) {
			if (
				verwaltbarerAusfuellerInstitutionUndRecherchekomplexDto.ausfuellerId ===
				ausfueller.id
			) {
				this.verwaltbarerAusfueller = ausfueller;
				return this.verwaltbarerAusfueller;
			}
		}
	}
}
