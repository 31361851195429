<div class="row well">
	<div class="col-xs-12">
		<h2>{{ ueberschrift }}</h2>

		<div *ngFor="let recherchekomplex of recherchekomplexe">
			<div class="headlineWithActions">
				<h3>{{ recherchekomplex.name }}</h3>
				<div class="btn-group actions">
					<p-dropdown [options]="items" [(ngModel)]="selectedReport" optionLabel="label"
								optionValue="value" tooltipPosition="left" placeholder="Reports">
						<ng-template let-item pTemplate="item">
							<a (click)="downloadReport(recherchekomplex)"
							   href="" role="menuitem" appPreventDefault>
								Erneut eingereichte Fragebögen Report
							</a>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<ul *ngIf="existierenFrageboegenFuerRecherchekomplex(recherchekomplex)" class="list-group">
				<li
					(click)="onClick(fragebogen)"
					*ngFor="let fragebogen of getFrageboegenFuerRecherchekomplex(recherchekomplex)"
					class="list-group-item clearfix"
				>
					<span class="item-text">{{ fragebogen.name }}</span>
					<i
						*ngIf="fragebogen.hatVorjahresfragebogen()"
						class="icon-link vorjahresfragebogen"
						title="Verknüpft mit {{fragebogen.vorjahresfragebogen.name}}"
					></i>
					<span class="actions" ngPreserveWhitespaces>
						<app-editor-fragebogen-list-veroeffentlichen-modal
							*ngIf="!fragebogen.veroeffentlicht"
							[fragebogen]="fragebogen"
							(fragebogenVeroeffentlicht)="onFragebogenVeroeffentlicht($event)"
						></app-editor-fragebogen-list-veroeffentlichen-modal>
						<app-editor-fragebogen-list-duplicate-modal
							[fragebogen]="fragebogen"
							(duplicateCreated)="onDuplicateCreated($event)"
						></app-editor-fragebogen-list-duplicate-modal>

						<button
							(click)="loesche(fragebogen)"
							*ngIf="!fragebogen.veroeffentlicht"
							appPreventDefault
							class="btn btn-xs"
							ngPreserveWhitespaces
							title="Fragebogen&nbsp;löschen"
							type="button"
						>
							<i class="icon-trash"></i> Löschen
						</button>
					</span>
				</li>
			</ul>

			<em *ngIf="!existierenFrageboegenFuerRecherchekomplex(recherchekomplex)" class="keineFrageboegen">
				Keine Fragebögen vorhanden
			</em>
		</div>

		<div *ngIf="!recherchekomplexe" class="keine-recherchekomplexe">
			<em>Keine Recherchekomplexe vorhanden</em>
		</div>
	</div>
</div>
