import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs/internal/Observable";
import {UserService} from "../form-viewer/service/UserService";

@Injectable()
export class AuthGuardViewer {

	constructor(private userService: UserService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let ausfueller = this.userService.getAusfueller();
		if (ausfueller) {
			if (ausfueller.istAusfueller()) {
				return true
			} else {
				return this.router.navigate(['/403'])
			}
		}
		return false;

		//else {
		//	this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}})
		//}
	}

}
