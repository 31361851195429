<app-ausfueller-verwaltung-dialog #dialog></app-ausfueller-verwaltung-dialog>

<nav class="navbar navbar-abschnitt">
	<div class="container">
		<ul class="nav navbar-nav">
			<li *ngIf="!isSaving()" class="navbtn back abschnittback">
				<a (mousedown)="onZurueckZurUebersichtClicked()"
				   appPreventDefault
				   href
				   title="Zum Fragebogen"
				   placement="bottom">
					<i class="icon icon-chevron-small-left"></i>
				</a>
			</li>
			<li class="switcher">
				<div class="headline">
					{{ abschnitt.ueberschrift }}
				</div>
				<div class="description">
					Der Abschnitt enthält {{ abschnitt.fragen.length | plural:'eine Frage':'Fragen' }}
				</div>
			</li>
			<li class="navbtn abschnittsausfueller" *ngIf="istAbschnittsausfuellerVerwaltenAktiv()">
				<a [routerLink]="['/benutzerverwaltung']">
					<i class="glyphicon glyphicon-user"></i> +
				</a>
			</li>
		</ul>
	</div>
</nav>
