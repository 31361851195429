<div class="hero">
	<div class="keyvisual" [style.background-image]="keyvisual_image ? 'url(' + keyvisual_image + ')': ''">
		<app-header [style]="'dark'"></app-header>
	</div>

	<div class="container">
		<div class="row">
			<div class="col-md-8 col-md-offset-2 welcome">
				<h2 class="institution">{{ institution.anzeigename }}</h2>
				<h1>Guten Tag, {{ ausfueller?.anrede }} {{ ausfueller?.anzeigename }}.</h1>
				<div *ngIf="content; else elseBlock" [innerHTML]="content"></div>
				<ng-template #elseBlock>
					<p>
						Der JUVE Verlag steht seit über zwei Jahrzehnten für kompetente Berichterstattung über den Rechtsmarkt.
						Für Entscheider sind unsere Rankings und Analysen ein wichtiger Wegweiser.
					</p>

					<p>Mit JUVE Recherche haben Sie die Möglichkeit, ganzjährig Informationen zu Ihrem Geschäft zu sammeln –
					   die erst an JUVE übermittelt werden, wenn Sie auf den Button „Fragebogen an JUVE übermitteln“ klicken.
					   Öffnen Sie einfach den gewünschten Fragebogen und schon können Sie loslegen.
					   Ihre Angaben sind ausschließlich für unsere Publikationen bestimmt. Haben Sie Fragen?
					   Antworten finden Sie in unseren <a href="faq">„Fragen & Antworten“</a>.
					</p>
					<p><strong>Vielen Dank!</strong></p>
				</ng-template>
				<div class="giant-arrow">
					<a href="#frageboegen"
					   onclick="window.location.hash = 'frageboegen'; return false;">
						<i class="icon-chevron-thin-down"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<a name="frageboegen"></a>

<app-viewer-portal-recherchekomplex
	[recherchekomplex]="recherchekomplex"
	[recherchekomplexFrist]="recherchekomplexFrist"
	[showSpeichernInfo]="showSpeichernInfo"
	[showZeitraeumeInfo]="showZeitraeumeInfo"
	[showSubmissionInfo]="showSubmissionInfo">
	<app-spinner></app-spinner>
</app-viewer-portal-recherchekomplex>

<app-teaser></app-teaser>

<app-footer></app-footer>
