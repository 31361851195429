import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser";
import { Ausfueller } from "@form-viewer/model/Ausfueller";
import { AusfuellerBerechtigungsService } from "@form-viewer/service/AusfuellerBerechtigungsService";
import { UserService } from "@form-viewer/service/UserService";
import { ViewerPortalRecherchekomplexComponent } from '@form-viewer/viewer-portal-recherchekomplex/ViewerPortalRecherchekomplexComponent';
import { select, Store } from "@ngrx/store";
import { FooterComponent } from '@shared/components/footer/FooterComponent';
import { HeaderComponent } from '@shared/components/header/HeaderComponent';
import { SpinnerComponent } from '@shared/components/spinner/SpinnerComponent';
import { Institution } from "@shared/model/Institution";
import { Recherchekomplex } from "@shared/model/Recherchekomplex";
import { selectInstitution } from "@store/selector/institution.selectors";
import { selectRecherchekomplex } from "@store/selector/recherchekomplex.selectors";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ContentService } from "../services/ContentService";
import { TeaserComponent } from './TeaserComponent';

@Component({
			   selector: 'app-portal',
			   templateUrl: './PortalComponent.html',
			   styleUrls: ['PortalComponent.less'],
	standalone: true,
	imports: [
		HeaderComponent, NgIf, ViewerPortalRecherchekomplexComponent, SpinnerComponent, TeaserComponent, FooterComponent
	]
		   })
export class PortalComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	content: SafeHtml
	ausfueller: Ausfueller
	institution: Institution
	recherchekomplex: Recherchekomplex
	recherchekomplexFrist: string
	showSpeichernInfo: boolean
	showZeitraeumeInfo: boolean
	showSubmissionInfo: boolean
	keyvisual_image: string;

	constructor(private sanitizer: DomSanitizer,
				private userService: UserService,
				private store: Store,
				private contentService: ContentService,
				private messageService: MessageService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private title: Title) {
		title.setTitle('Portal - Juve Recherche')
	}

	ngOnInit(): void {

		try {

			try {
				this.messageService.clear();
			}
			finally {

			}

			this.ausfueller = this.userService.getAusfueller()
			this.store.pipe(select(selectRecherchekomplex)).subscribe(value => this.recherchekomplex = new Recherchekomplex(value));
			this.store.pipe(select(selectInstitution)).subscribe(value => this.institution = new Institution(value));
			this.loadDataByRecherchekomplex(this.recherchekomplex)//TODO: Daten in DB speichern
			this.contentService.holePortalContent().then(dto => this.content = dto.content ? this.sanitizer.bypassSecurityTrustHtml(dto.content) : '');
			this.ausfuellerBerechtigungsService.clearCache();
		} finally {
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal()
				} catch (exception) {
				}
			}, 1000);
			this.userService.isAlive()
		}
	}

	loadDataByRecherchekomplex(recherchekomplex: Recherchekomplex) {
		switch (recherchekomplex.name) {
			case 'JUVE Handbuch Steuern 2019':
				this.keyvisual_image = 'assets/portal/steuermarkt_handbuch_steuern_2019_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 10.10.2018'
				break
			case 'JUVE Handbuch Steuern 2020':
				this.keyvisual_image = 'assets/portal/Steuermarkt_Portal_Bueroszene_2020_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 07.10.2019'
				break
			case 'JUVE Handbuch Steuern 2021':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_2021_XL7.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 14.10.2020'
				break
			case 'JUVE Handbuch Steuern 2022':
				this.keyvisual_image = 'assets/portal/Lith-Farbb-Portal-Büroszene_159735745_XL10.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 27.10.2021'
				break
			case 'JUVE Handbuch Steuern 2023':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_Bettina_159735745_XL12.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 26.10.2022'
				break
			case 'JUVE Handbuch Steuern 2024':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_August_2023_mit_Eva.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 25.10.2023'
				this.showSpeichernInfo = true
				break
			case 'JUVE Handbuch Steuern 2025. Teil 1':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_August_2024.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 30.10.2024'
				break
			case 'JUVE Handbuch Steuern 2025. Teil 2':
				this.keyvisual_image = 'assets/portal/Jurec_Foto_DSC_0906.jpg'
				this.recherchekomplexFrist = '17. März 2025 und 16. Juni 2025'
				this.showZeitraeumeInfo = true
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2018/19':
				this.keyvisual_image = 'assets/portal/DHB_Recherche_online_Ansicht_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 05.04.2018'
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2019/20':
				this.keyvisual_image = 'assets/portal/DHB_Recherche_online_Ansicht_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 05.04.2019'
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2020/21':
				this.keyvisual_image = 'assets/portal/Portalbild_Wirtschaft_2021.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 31.03.2020'
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2021/22':
				this.keyvisual_image = 'assets/portal/Bueroszene_Jurec_1575x891.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 31.03.2021'
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2022/23':
				this.keyvisual_image = 'assets/portal/Bueroszene_Jurec_XL_003.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 04.03.2022'
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2023/24':
				this.keyvisual_image = 'assets/portal/Bueroszene_Jurec_XL.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 06.03.2023'
				this.showSpeichernInfo = true
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2024/25':
				this.keyvisual_image = 'assets/portal/Bueroszene_Jurec_XL.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 06.03.2024'
				this.showSpeichernInfo = true
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2025/26 Teil 1':
				this.keyvisual_image = 'assets/portal/Jurec_Foto_DSC_0906.jpg'
				this.recherchekomplexFrist = '13. Dezember 2024, 17. März 2025 und 16. Juni 2025'
				this.showZeitraeumeInfo = true
				this.showSubmissionInfo = true
				break
			case 'JUVE Handbuch Wirtschaftskanzleien 2025/26 Teil 2':
				this.keyvisual_image = 'assets/portal/Jurec_Foto_DSC_0906.jpg'
				this.recherchekomplexFrist = '17. März 2025 und 16. Juni 2025'
				this.showZeitraeumeInfo = true
				this.showSubmissionInfo = true
				break
			case 'Personal- und Finanzkennzahlen 2019/20':
				this.keyvisual_image = 'assets/portal/DHB_RM10_159735745_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 14.02.2020, bei Geschäftsjahr ungleich Kalenderjahr bis 03.06.2020'
				break
			case 'Personal- und Finanzkennzahlen 2020/21':
				this.keyvisual_image = 'assets/portal/DHB_RM10_159735745_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 15.02.2021, bei Geschäftsjahr ungleich Kalenderjahr bis 31.05.2021'
				break
			case 'Personal- und Finanzkennzahlen 2021/22':
				this.keyvisual_image = 'assets/portal/DHB_RM10_159735745_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 15.02.2022, bei Geschäftsjahr ungleich Kalenderjahr bis 31.05.2022'
				break
			case 'Personal- und Finanzkennzahlen 2022/23':
				this.keyvisual_image = 'assets/portal/DHB_RM10_159735745_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 15.02.2023, bei Geschäftsjahr ungleich Kalenderjahr bis 31.05.2023'
				break
			case 'Personal- und Finanzkennzahlen 2023/24':
				this.keyvisual_image = 'assets/portal/DHB_RM10_159735745_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 15.02.2024, bei Geschäftsjahr ungleich Kalenderjahr bis 31.05.2024'
				break
			case 'Personal- und Finanzkennzahlen 2024/25':
				this.keyvisual_image = 'assets/portal/Jurec_Foto_DSC_0906.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 17.02.2025, bei Geschäftsjahr ungleich Kalenderjahr bis 30.05.2025'
				break
			case 'Personal- und Finanzkennzahlen Steuern 2020/21':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_159735745_XL9.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 14.02.2021, bei Geschäftsjahr ungleich Kalenderjahr bis 03.06.2021'
				break
			case 'Personal- und Finanzkennzahlen Steuern 2021/22':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_159735745_XL11.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bei Geschäftsjahr gleich Kalenderjahr bis 22.02.2022, bei Geschäftsjahr ungleich Kalenderjahr bis 31.05.2022'
				break
			case 'Personal- und Finanzkennzahlen Steuern 2022/23':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Büroszene_01_2023.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 24.02.2023'
				break
			case 'Personal- und Finanzkennzahlen Steuern 2023/24':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_August_2023_ohne_Eva.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 19.02.2024'
				break
			case 'Steuermarkt Deutschland':
				this.keyvisual_image = 'assets/portal/keyvisual.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 09.10.2017'
				break
			case 'Top-Arbeitgeber für Juristen 2019':
				this.keyvisual_image = 'assets/portal/top_arbeitgeber_fuer_juristen_2019.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 02.10.2018'
				break
			case 'Top-Arbeitgeber für Juristen 2020':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_2020.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 18.09.2019'
				break
			case 'Top-Arbeitgeber für Juristen 2021':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_08_20_Recherche.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 20.09.2020'
				break
			case 'Top-Arbeitgeber für Juristen 2022':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_08_21_Recherche.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 20.09.2021'
				break
			case 'Top-Arbeitgeber für Juristen 2023':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_08_22_Recherche.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 21.09.2022'
				break
			case 'Top-Arbeitgeber für Juristen 2024':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_08_22_Recherche.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 21.9.2023'
				this.showSpeichernInfo = true
				break
			case 'Top-Arbeitgeber für Juristen 2025':
				this.keyvisual_image = 'assets/portal/Bueroszene_azur_online_08_22_Recherche.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 18.9.2024'
				break
			case 'Top-Arbeitgeber im Steuermarkt 2020':
				this.keyvisual_image = 'assets/portal/Steuermarkt_Portal_Bueroszene_2020_XL5.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 11.10.2019'
				break
			case 'Top-Arbeitgeber im Steuermarkt 2021':
				this.keyvisual_image = 'assets/portal/Steuermarkt_Portal_Bueroszene_2021_XL8.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 16.10.2020'
				break
			case 'Top-Arbeitgeber im Steuermarkt 2023':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Büroszene_ok_159735745_XL12.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 21.10.2022'
				break
			case 'Top-Arbeitgeber im Steuermarkt 2024':
				this.keyvisual_image = 'assets/portal/Lith_Farbb_Portal_Bueroszene_August_2023_ohne_Eva.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 22.10.2023'
				break
			case 'Top-Arbeitgeber im Steuermarkt 2025':
				this.keyvisual_image = 'assets/portal/Jurec_Foto_DSC_0906.jpg'
				this.recherchekomplexFrist = 'Frist: Übermittlung an JUVE bis 09.10.2024'
				break
		}


	}
}
