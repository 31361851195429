import {NgIf} from "@angular/common";
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {FormEditorHeaderComponent} from '@form-editor/editor-header/FormEditorHeaderComponent';
import {AlertComponent} from '@shared/alert/component/AlertComponent';
import {Fragebogen} from '@shared/model/Fragebogen';
import {Recherchekomplex} from '@shared/model/Recherchekomplex';
import {FragebogenService} from '@shared/service/FragebogenService';
import {RecherchekomplexService} from '@shared/service/RecherchekomplexService';
import {isDefined} from '@util/isDefined';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {TabViewModule} from 'primeng/tabview';
import {EditorFragebogenListComponent} from './fragebogen-liste/EditorFragebogenListComponent';

@Component({
	selector: "app-editor-fragebogen-uebersicht",
	templateUrl: "./EditorFragebogenUebersichtComponent.html",
	styleUrls: ["./EditorFragebogenUebersichtComponent.less"],
	standalone: true,
	imports: [
		AlertComponent,
		FormEditorHeaderComponent,
		EditorFragebogenListComponent,
		TabViewModule,
		NgIf,
	],
})
export class EditorFragebogenUebersichtComponent implements OnInit {
	parseFragebogenAttributeValue(arg0: string) {
		throw new Error('Method not implemented.');
	}
	@BlockUI() blockUI: NgBlockUI;

	recherchekomplexe: Recherchekomplex[];
	bearbeitbareFrageboegen: { [key: string]: Fragebogen[] } = {};
	veroeffentlichteFrageboegen: { [key: string]: Fragebogen[] } = {};
	archivierteFrageboegen: { [key: string]: Fragebogen[] } = {};
	index: number;

	constructor(
		private recherchekomplexService: RecherchekomplexService,
		private fragebogenService: FragebogenService,
		private title: Title
	) {
		title.setTitle("Fragebogen-Liste - Juve Recherche");
	}

	ngOnInit(): void {
		this.blockUI.start("Daten werden verarbeitet...");
		this.recherchekomplexService
			.getRecherchekomplexe()
			.then(
				(recherchekomplexe) =>
					(this.recherchekomplexe = recherchekomplexe)
			)
			.then(() =>
				this.fragebogenService
					.getFrageboegen()
					.then((frageboegen) => this.groupFrageboegen(frageboegen))
			);

		const hash = window.location.hash.replace("#", "");
		this.index = isNaN(+hash) ? 0 : +hash;
		this.blockUI.stop();
	}

	private groupFrageboegen(frageboegen: Fragebogen[]) {
		if (!isDefined(frageboegen)) {
			this.bearbeitbareFrageboegen = {};
			this.veroeffentlichteFrageboegen = {};
			this.archivierteFrageboegen = {};
			return;
		}

		const bearbeitbareFrageboegen = frageboegen.filter(
			(fragebogen) => !fragebogen.veroeffentlicht
		);
		const veroeffentlichteFrageboegen = frageboegen.filter(
			(fragebogen) => fragebogen.veroeffentlicht && fragebogen.recherchekomplex.kannNochAusgefuelltWerden()
		);
		const archivierteFrageboegen = frageboegen.filter(
			(fragebogen) => fragebogen.veroeffentlicht && !fragebogen.recherchekomplex.kannNochAusgefuelltWerden()
		);

		this.recherchekomplexe.forEach((rk) => {
			this.bearbeitbareFrageboegen[rk.id] =
				bearbeitbareFrageboegen.filter(
					(fb) => fb.recherchekomplex.id === rk.id
				);
			this.veroeffentlichteFrageboegen[rk.id] =
				veroeffentlichteFrageboegen.filter(
					(fb) => fb.recherchekomplex.id === rk.id
				);
			this.archivierteFrageboegen[rk.id] =
				archivierteFrageboegen.filter(
					(fb) => fb.recherchekomplex.id === rk.id
				);
		});
	}

	onDuplicateCreated($event: Fragebogen) {
		this.bearbeitbareFrageboegen[$event.recherchekomplex.id].push($event);
	}

	onFragebogenVeroeffentlicht($event: Fragebogen) {
		const rkId = $event.recherchekomplex.id;
		const index = this.bearbeitbareFrageboegen[rkId].indexOf($event);
		this.bearbeitbareFrageboegen[rkId].splice(index, 1);
		this.veroeffentlichteFrageboegen[rkId].push($event);
	}
}
